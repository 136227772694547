<template>
  <div>
    <el-row>
      <el-col :span="20" :offset="2">
        <div v-for="(item, index) in productList" :key="index" class="product-item">
          <el-image
          style="height: 100px"
          :src="require(`../assets/phone/${item.path}`)"
          :fit="'contain'"></el-image>
          <div>
            <h2>{{item.name}}</h2>
            <h4>{{item.price}}</h4>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters/*, mapMutations*/} from "vuex";
export default {
  name: 'Basket',
  computed:{
    ...mapGetters({
      productList: "productList"
    }),
  },
}
</script>
<style scoped>
.product-item{
  text-align: left;
  border: solid 1px rgb(190, 190, 190);
  margin: 20px 0px;
  display: flex;
  justify-content: left;
}
.el-image{
  background: lightgray;
  padding: 10px;
  margin-right: 15px;
}
</style>